var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pageContainer",staticClass:"pdf-page",style:({
    height: (_vm.scaledHeight + "px"),
    width: (_vm.scaledWidth + "px"),
    background: _vm.$themeTokens.surface,
  }),attrs:{"id":("pdf-page-" + _vm.pageNum),"role":"region","aria-label":_vm.$tr('numPage', { number: _vm.pageNum, total: _vm.totalPages })}},[_c('span',{staticClass:"loading",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.$formatNumber(_vm.pageNum)))]),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.rendered),expression:"rendered"}],ref:"canvas",staticClass:"canvas",attrs:{"dir":"ltr","height":_vm.scaledHeight,"width":_vm.scaledWidth}}),_c('div',{ref:"textLayer",staticClass:"text-layer",style:({
      height: (_vm.scaledHeight + "px"),
      width: (_vm.scaledWidth + "px")
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }